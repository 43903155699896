@import '/src/styles/utilities.scss';

$animation-duration: 250ms;

.menus__list {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // Add height to ensure the dropdowns are positioned correctly
  height: $height-nav-mobile;
  padding: 0 $space-32;
  margin: 0;
  list-style: none;

  @include media-breakpoint-up(lg) {
    @include create-container();

    gap: $space-32;
    align-items: center;
    justify-content: flex-start;
    height: $height-nav-desktop;
    padding: 0;
  }
}

.menus__burger {
  animation: fade-in $animation-duration ease-in 1;
}

.menus__logo {
  @include media-breakpoint-between(xs, md) {
    max-width: 150px;
    margin-right: auto;
    margin-left: auto;
  }

  @include media-breakpoint-up(lg) {
    max-width: 224px;
    margin-right: $space-12;
  }
}

.menus__triggerText {
  @include apply-display-styles('navigation');

  display: flex;
  gap: $space-4;
  align-items: center;
  color: var(--color-text-light);
  white-space: nowrap;
  animation: fade-in $animation-duration ease-in 1;

  &:focus {
    color: var(--color-text-light);
  }

  &:focus-visible {
    outline: 1px solid var(--color-blue-300);
  }

  &:hover {
    color: var(--color-text-light);
  }
}

.menus__authButtons {
  animation: fade-in $animation-duration ease-in 1;

  @include media-breakpoint-up(lg) {
    display: flex;
    gap: $space-32;
    align-items: center;
    margin-left: auto;
  }
}

.menus__accountDrawer {
  position: relative;
  display: flex;
  align-items: center;
  animation: fade-in $animation-duration ease-in 1;

  @include media-breakpoint-up(lg) {
    position: static;
    margin-left: auto;
  }
}

.menus__viewport {
  position: absolute;
  z-index: $zindex-popover;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
