@import '/src/styles/utilities.scss';

$animation-duration: 250ms;
$dropdown-content-min-height: 285px;

.dropdownContent {
  @include create-container();

  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: $space-48;
  min-height: $dropdown-content-min-height;
  margin: 0 auto;
  color: var(--color-text-dark);
  animation: fade-in $animation-duration ease-in 1;
}

.dropdownContent__copy {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-48;
  align-content: center;
}

.dropdownContent__header {
  margin-bottom: $space-20;
  color: var(--color-gray-800);
}

.dropdownContent__richText {
  p {
    @include apply-display-styles('d4');

    padding: 0;
    color: var(--color-black);
  }
}

.dropdownContent__list {
  display: flex;
  flex-direction: column;
  gap: $space-20;
  padding: 0;
  margin: 0;
  list-style: none;
}

.dropdownContent__link {
  @include primary-stroked-effects();

  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: $button-height-small;
  padding: $button-padding-small;
  font-family: $font-primary;
  font-weight: $font-weight-bold;
  white-space: nowrap;
}

.dropdownContent__imageWrapper {
  position: relative;
}

.dropdownContent__image {
  position: absolute;
  top: 25px;
  max-width: var(--dropdown-image-size);
  height: auto;
  animation: fade-in $animation-duration ease-in 1;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
