@import '/src/styles/utilities.scss';

.navigationDropdown__trigger {
  @include undo-default-button-styles();

  position: relative;

  &:focus {
    color: var(--color-text-light);
  }

  &:focus-visible {
    outline: 1px solid var(--color-blue-300);
  }

  // Little circle
  &[data-state='open'] {
    &:after {
      position: absolute;
      bottom: -4rem;
      left: 50%;
      z-index: $zindex-active;
      width: $space-16;
      height: $space-16;
      content: '';
      background-color: var(--color-white);
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }
}

.navigationDropdown__content {
  width: 100%;
  background-color: var(--color-white);
  border-bottom-right-radius: $border-radius-30;
  border-bottom-left-radius: $border-radius-30;
  box-shadow: $elevation-4;

  [data-state='open'] & {
    opacity: 1;
  }

  [data-state='closed'] & {
    opacity: 0;
  }
}
